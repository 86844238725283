function upload({config, file}) {

    let formData = new FormData();
    
    formData.append('entity',   config.entity);    
    formData.append('property', config.property);    
    formData.append('vault',    config.vault);    
    formData.append('route',    config.route);    
    formData.append('meta',     config.meta);    
    formData.append('file', file);     

    return window.axios.post('/api/files/upload', formData, { "Content-Type": "multipart/form-data" });
}
export default {
    upload
}