import { email, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
    validate(value) {
        return {
          required: true,
          valid: ['', null, undefined].indexOf(value) === -1
        };
    },
    computesRequired: true,
    message: (fieldName) => {
        return `El campo ${fieldName} requerido`;
    }
});

extend("max", {
  ...max,
  message: "This field must be {length} characters or less"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});
