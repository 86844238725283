const namespaced = true

const state = {
    currentLocation:{
        lat : 0,
        lng : 0
    },
    mapCenter:{
        lat: 0,
        lng: 0,
    },
    markersCollection : []
}
const mutations = {
    MUTATION_SET_CURRENT_POSITION(state, {lat, lng}){
         state.currentLocation = {'lat' : lat, 'lng' : lng};
    },
    MUTATION_SET_MARKERS_COLLECTION(state, collection){
         state.markersCollection = collection;
    },
    MUTATION_SET_CENTER(state, {lat, lng}){
         state.mapCenter = {'lat' : lat, 'lng' : lng};
    }
}
const actions = {
     // Name Property
    getCurrentLocation(context) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = { lat: position.coords.latitude, lng: position.coords.longitude};                
                context.commit('MUTATION_SET_CURRENT_POSITION', pos);
            },
            () => { context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : 'Error al obtener ubicación'}, {root: true });});
        } else {
            context.dispatch('notifications/add', {'color' : 'error',  'visibility' : true,  'timeout' : 5000, 'text' : 'Error navegador'}, {root: true });
        }        
    },
    processMarkers(context,data){
        var markers = [];        

        for(let i = 0; i < data.length; i++ ){
            markers.push({
                position: { lat : parseFloat(data[i].latitude), lng: parseFloat(data[i].longitude)},
                title: data[i].name + ' '+ data[i].address,
                // icon: this.getSiteIcon(1)    // if you want to show different as per the condition.         
          });            
        }
        context.commit('MUTATION_SET_MARKERS_COLLECTION', markers);
    },
    setCenter(context,data){        
        context.commit('MUTATION_SET_CENTER', data);
    },
    setMarkersCollection(context,data){
        context.commit('MUTATION_SET_MARKERS_COLLECTION', data);
    }
        
}

export default {
     namespaced,
     state,
     mutations,
     actions
}